var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "matkp" }, [
    _c(
      "div",
      { staticClass: "f-col fill-height" },
      [
        _c("matkp-nav", { attrs: { glass: "" } }),
        _c(
          "div",
          { staticClass: "mat-body" },
          [
            [
              _c("matkp-hero"),
              _c("div", { staticClass: "content-wrap" }, [
                _c(
                  "div",
                  {
                    staticClass: "f-row align-h-center",
                    staticStyle: { gap: "50px", margin: "40px 0 0 0" },
                  },
                  [
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "f-col", staticStyle: { gap: "10px" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "f-row",
                            staticStyle: { gap: "50px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "f-col",
                                staticStyle: { gap: "20px" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    style: `width:${_vm.$parent.anatomyWidth}px`,
                                  },
                                  [
                                    _c(
                                      "select",
                                      {
                                        on: {
                                          change: _vm.$parent.selectSpecies,
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: {
                                              value: "human",
                                              selected: "",
                                            },
                                          },
                                          [_vm._v("Human")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "mouse" } },
                                          [_vm._v("Mouse")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "f-col anatomy",
                                    staticStyle: { gap: "10px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "anatomy-item",
                                        style: `width:${_vm.$parent.anatomyWidth}px`,
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "anatomy-points",
                                            staticStyle: {
                                              position: "absolute",
                                              width: "100%",
                                              height: "100%",
                                              "z-index": "1",
                                            },
                                          },
                                          [
                                            _vm._l(
                                              _vm.$parent.depots[
                                                _vm.$parent.selectedSpecies
                                              ],
                                              function (depot) {
                                                return _vm._l(
                                                  depot.pos,
                                                  function (point) {
                                                    return depot.pos.length >
                                                      0 &&
                                                      depot.pos[0].length === 2
                                                      ? _c("div", {
                                                          staticClass:
                                                            "depot-point on",
                                                          staticStyle: {
                                                            position:
                                                              "absolute",
                                                            width: "15px",
                                                            height: "15px",
                                                            transform:
                                                              "translate(-10%,-10%)",
                                                            "border-radius":
                                                              "50%",
                                                            background: "gold",
                                                          },
                                                          style: `left:${
                                                            point[0] *
                                                            _vm.$parent
                                                              .anatomyImages[
                                                              _vm.$parent
                                                                .selectedSpecies
                                                            ].scale
                                                          }px; top:${
                                                            point[1] *
                                                            _vm.$parent
                                                              .anatomyImages[
                                                              _vm.$parent
                                                                .selectedSpecies
                                                            ].scale
                                                          }px`,
                                                          attrs: {
                                                            "data-depot": `${depot.ontology}`,
                                                            "data-category":
                                                              depot.category,
                                                          },
                                                        })
                                                      : _vm._e()
                                                  }
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "50%",
                                                overflow: "hidden",
                                                right: "0",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                style: `width:${_vm.$parent.anatomyWidth}px`,
                                                attrs: {
                                                  src: _vm.$parent
                                                    .anatomyImages[
                                                    _vm.$parent.selectedSpecies
                                                  ].male,
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                                transform: "scaleX(-1)",
                                                width: "50%",
                                                overflow: "hidden",
                                                "border-right":
                                                  "1px dashed black",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                style: `width:${_vm.$parent.anatomyWidth}px`,
                                                attrs: {
                                                  src: _vm.$parent
                                                    .anatomyImages[
                                                    _vm.$parent.selectedSpecies
                                                  ].female,
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "font-size": "12px",
                                      height: "40px",
                                    },
                                    style: `width:${_vm.$parent.anatomyWidth}px`,
                                  },
                                  [_vm._v(_vm._s(_vm.$parent.depotDescription))]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "f-col",
                                staticStyle: { gap: "15px" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "18px",
                                      "line-height": "40px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [_vm._v("Adipose tissue depots")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "depot-items",
                                    staticStyle: { "max-height": "400px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "f-row",
                                        staticStyle: { gap: "15px" },
                                      },
                                      [
                                        _vm._l(
                                          _vm.$parent.depotsByCategory,
                                          function (depots, category) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "f-col",
                                                  staticStyle: { gap: "5px" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "depot-category",
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                        "text-transform":
                                                          "capitalize",
                                                        padding: "5px 0",
                                                      },
                                                      on: {
                                                        mouseover: function (
                                                          $event
                                                        ) {
                                                          return _vm.$parent.highlightDepotCategory(
                                                            category
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(category) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    depots,
                                                    function (depot) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "depot-item f-row spread-out",
                                                          on: {
                                                            mouseover:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.$parent.highlightDepot(
                                                                  $event,
                                                                  depot
                                                                )
                                                              },
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$parent.goToDatasets(
                                                                $event,
                                                                depot
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(depot.name)
                                                            ),
                                                          ]),
                                                          depot.count > 0
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "(" +
                                                                    _vm._s(
                                                                      depot.count
                                                                    ) +
                                                                    ")"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
          ],
          2
        ),
        _c("matkp-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "f-col",
        staticStyle: {
          width: "300px",
          "font-size": "14px",
          gap: "20px",
          "text-align": "justify",
        },
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              "font-size": "18px",
              "line-height": "40px",
              "font-weight": "bold",
            },
          },
          [_vm._v("Where is adipose tissue found?")]
        ),
        _c("div", [
          _vm._v(
            "Adipose tissue is found throughout the body in different depots. While many depots have orthologs across all mammals, there are also some important species-specific differences in depot size, type, and location."
          ),
        ]),
        _c("div", [
          _vm._v(
            "Depots can be classified in several ways. Many authors emphasize a distinction between subcutaneous (under the skin and outside of the major body cavities) vs. visceral depots (which includes fat in the peritoneal and thoracic cavities). A large amount of mammalian adipose tissue falls within one of these categories, but not all. As one example, fat in the bone marrow cannot be classified as subcutaneous or visceral. Depots can also be classified by their content of thermogenic adipocytes, identified as “white” or “brown”. Here again, many depots contain a mixture of brown and white adipocytes, which may vary even within the same depot, depending on the ambient temperature. "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }