import { render, staticRenderFns } from "./Template.vue?vue&type=template&id=369a849b&scoped=true"
var script = {}
import style0 from "./Template.vue?vue&type=style&index=0&id=369a849b&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "369a849b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ec2-user/.jenkins/workspace/Portal (Dev)/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('369a849b')) {
      api.createRecord('369a849b', component.options)
    } else {
      api.reload('369a849b', component.options)
    }
    module.hot.accept("./Template.vue?vue&type=template&id=369a849b&scoped=true", function () {
      api.rerender('369a849b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/MATKP/Index/Template.vue"
export default component.exports