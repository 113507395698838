var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "hero-wrap f-row align-h-center align-v-center" },
    [
      _c("div", { staticClass: "hero-interact fill-width fill-height" }),
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "f-col hero-info no-events",
          staticStyle: { "z-index": "1", gap: "20px" },
        },
        [
          _c("div", { staticClass: "tagline" }, [
            _vm._v(
              "A resource for the exploration of genes, traits and cell types in the function of adipose tissue across species, depots and conditions."
            ),
          ]),
          _c(
            "a",
            {
              staticClass: "matkp-input",
              staticStyle: { "pointer-events": "all" },
              attrs: { href: "/matkp/datasets.html" },
            },
            [_vm._v("Browse all Datasets")]
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                margin: "-10px 0",
                "text-align": "center",
              },
            },
            [_vm._v("or")]
          ),
          _c(
            "b-input-group",
            {
              staticStyle: { "pointer-events": "none", opacity: ".7" },
              attrs: { size: "sm" },
            },
            [
              _c("input", {
                attrs: {
                  type: "text",
                  placeholder: "Search gene, cell type or trait",
                },
              }),
              _c(
                "b-button",
                {
                  staticClass: "button-lock-right",
                  on: { click: function ($event) {} },
                },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "hero-label f-col align-v-center no-events" },
      [
        _c("div", { staticClass: "logo f-row align-v-center" }, [
          _c("img", {
            attrs: {
              src: "https://hugeampkpncms.org/sites/default/files/users/user32/matkp/matkplll.png",
            },
          }),
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "22px",
                "line-height": "22px",
                "font-weight": "bold",
              },
            },
            [
              _vm._v(" Mammalian"),
              _c("br"),
              _vm._v("Adipose"),
              _c("br"),
              _vm._v("Tissue"),
              _c("br"),
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "15px",
                    display: "block",
                    "font-weight": "normal",
                  },
                },
                [_vm._v("Knowledge Portal")]
              ),
            ]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }